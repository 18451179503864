import { useNavigate } from 'react-router-dom';

const PaymentFail = () => {

    return (
        <>
            <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom auth-bg position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
                style={{ backgroundSize: 'cover' }}>
                <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 m-auto d-flex flex-column align-items-center">
                    <img src="/media/logos/iToplight_logo.png" alt="iToplight Logo" className="w-50 img-fluid" />
                    <div className='text-center'>
                        <h1 className='text-danger mt-7'>Payment Failed</h1>
                        <h3 className='mt-7 text-danger' style={{ lineHeight: 1.5 }}>
                            Please contact Pointguard at <a className='text-primary' href='mailto:info@pointguard.se'>info@pointguard.se</a> <br />for more help.
                        </h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentFail;