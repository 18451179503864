// import React, { useRef } from 'react';

// const PlaylistPreview = ({ previewUrl, height, width }: any) => {

//     const videoRef = useRef<HTMLVideoElement>(null);

//     const handleMouseEnter = () => {
//         if (videoRef.current) {
//             videoRef.current.play();
//         }
//     };

//     const handleMouseLeave = () => {
//         if (videoRef.current) {
//             videoRef.current.pause();
//             videoRef.current.currentTime = 0;
//         }
//     };

//     const cacheBustedUrl = `${previewUrl}?t=${new Date().getTime()}`;

//     return (
//         <React.Fragment>
//             <div className="preview-container" onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}>
//                 <video ref={videoRef} muted loop width={width || '180px'} height={height || '48px'}>
//                     <source src={cacheBustedUrl} type="video/mp4" />
//                     Your browser does not support the video tag.
//                 </video>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PlaylistPreview;

import React, { useRef, useState } from 'react';

const PlaylistPreview = ({ previewUrl, pngPreviewUrl, height, width }: any) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleClick = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const cacheBustedUrl = `${previewUrl}?t=${new Date().getTime()}`;
    const cacheBustedPngUrl = `${pngPreviewUrl}?t=${new Date().getTime()}`;

    return (
        <React.Fragment>
            <div
                className="preview-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                style={{ width: width || '180px', height: height || '48px', overflow: 'hidden' }}
            >
                {!isPlaying && (
                    <img
                        src={cacheBustedPngUrl}
                        alt="Playlist Preview"
                        style={{
                            width: '100%',
                            objectFit: 'cover',
                            height: height || '48px',
                        }}
                    />
                )}
                <video
                    ref={videoRef}
                    muted
                    loop
                    style={{
                        display: isPlaying ? 'block' : 'none',
                        width: width || '180px',
                        height: height || '48px',
                    }}
                >
                    <source src={cacheBustedUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </React.Fragment>
    );
};

export default PlaylistPreview;
