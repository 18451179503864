import Dexie from 'dexie';

// Create a new Dexie database
const db: any = new Dexie('CustomerDatabase');

// Define the schema (table for customerList)
db.version(1).stores({
    customerList: '++id, list' // 'id' is auto-incremented, 'list' stores the actual customer data
});

// Function to save customerList to IndexedDB
export const saveCustomerList = async (customerList: any) => {
    try {
        // Clear the previous customerList entry
        await db.customerList.clear();

        // Save the new customer list
        await db.customerList.add({ list: customerList });
    } catch (error) {
        console.error('Failed to save customer list:', error);
    }
};

// Function to retrieve customerList from IndexedDB
export const getCustomerList = async () => {
    try {
        const result = await db.customerList.toArray();
        if (result.length > 0) {
            return result[0].list;
        }
        return [];
    } catch (error) {
        console.error('Failed to retrieve customer list:', error);
        return [];
    }
};

// Function to clear customerList from IndexedDB
export const clearCustomerList = async () => {
    try {
        await db.customerList.clear();
    } catch (error) {
        console.error('Failed to clear customer list:', error);
    }
};
