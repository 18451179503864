import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import { loadCustomerListFromIndexedDB } from './redux/CustomersRedux'
import { useDispatch } from 'react-redux'

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadCustomerListFromIndexedDB(dispatch);
  }, [dispatch]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer position='top-center' autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false} hideProgressBar={false} closeOnClick theme='dark' />
    </Suspense>
  )
}

export { App }
