import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import { UserModel } from '../models/UserModel'
import { clearCustomerList } from '../../../../db/Customersdb'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

let accessToken = localStorage.getItem('k')
let userTypes = localStorage.getItem('p')

if (accessToken !== null) {
  accessToken = atob(accessToken)
}

if (userTypes !== null) {
  userTypes = JSON.parse(atob(userTypes))
}

const user: any = {
  id: 1,
  username: 'admin',
  password: '',
  email: '',
  firstname: 'Admin',
  lastname: '',
  pic: '',
}

const verifyRolesAndSet = (roles: any) => {

  if (roles === null) {
    return null;
  }

  let role = null;

  let admin = roles.find((element: any) => element == 'Admin');
  let company = roles.find((element: any) => element == 'Company');
  let authority = roles.find((element: any) => element == 'Authority');
  let Advertiser = roles.find((element: any) => element == 'Advertiser');
  let Distributor = roles.find((element: any) => element == 'Distributor');


  if (admin !== undefined) {
    role = "PG";
  } else if (company !== undefined) {
    role = "TA"
  } else if (authority !== undefined) {
    role = "AU"
  } else if (Advertiser !== undefined) {
    role = "Advertiser"
  } else if (Distributor !== undefined) {
    role = "Distributor"
  }

  return role;

}

const initialAuthState: IAuthState = {
  user: accessToken ? user : undefined,
  accessToken: accessToken,
  secrateKey: '207XEOXZ1894017XVTWYOO20101',
  role: verifyRolesAndSet(userTypes),
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string | null
  secrateKey?: string | null
  WebApiKey?: string,
  role?: string | null
}
// const initialState = {}


export const reducer = persistReducer(
  { storage, key: 'v1-dhg-auth', whitelist: ['user', 'accessToken', 'secrateKey', 'WebApiKey', 'role'] },
  (state: IAuthState = initialAuthState, action: any) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload.Response.Token

        let role = verifyRolesAndSet(action.payload.Response.UserType);

        return { accessToken, secrateKey: '207XEOXZ1894017XVTWYOO20101', role }
      }

      case actionTypes.Register: {
        // const secrateKey = action.payload?.accessToken
        // return {secrateKey, user: undefined}
        return {}
      }

      case actionTypes.Logout: {
        localStorage.removeItem('k')
        localStorage.removeItem('p')
        localStorage.removeItem('customerId')
        localStorage.removeItem('group')
        // localStorage.removeItem('customerList')
        clearCustomerList();
        return { accessToken: null, user: null, role: null }
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined }
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (data: any) => ({ type: actionTypes.Login, payload: data }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
